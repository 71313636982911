.logo{
    margin-top:5%;
    margin-left: 35%;
    margin-right:40%;
    width:150px;
  }
  .email{
    margin-top:10%;
    margin-left: 15% !important;
    margin-right:15% !important; 
    width:350px;
  }
  .password{
    margin-top:15%;
    margin-left: 15% !important;
    margin-right:15% !important;
    width:350px;
  }
  .btn{
    /* margin-top:5% !important;
    margin-left: 37% !important;
    margin-right:30% !important; */
    border-radius:20px !important;
    padding-left: 40px!important;
    padding-right: 40px!important;
  
  }
  .gd{
    padding-left: 16% !important;
  }
  .gd1{
    padding-left:55px !important;
    line-height: 45px !important;
  
  }
  .mrl{
    margin-left: 230%;
  }